// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDInput from "components/MDInput";
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../../services/service';

function MaterialReceipt() {
    const [state, setState] = useState({ quantity: '', poquantity: '', billno: '', transportcharges: '', loadingcharges: '', totalamount: '' });
    const [supplierData, setSupplierData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [poData, setPOData] = useState([]);
    const [grnData, setGRNData] = useState([]);
    const [purchaseOrderData, setPurchaseOrderData] = useState('');
    const [purchaseOrderId, setPurchaseOrderId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [grnDate, setGRNDate] = useState(new Date());
    const [purchaseDate, setPurchaseDate] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    // const navigate = useNavigate();
    const getSupplier = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };
    const getPurchaseOrdersBySupplier = (id) => {
        if (id > 0) {
            fetch(
                service.GET_PURCHASE_ORDER_BY_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setPOData(result);
                    const data2 = result.map((item) => ({ value: item.purchaseOrderId, label: item.purchaseOrderNo }));
                    setPurchaseOrderData(data2);
                })
                .catch(err => {
                    if (err) {
                        setErrors(err);
                    };
                });
        }
    };

    const getGRNByPO = (id) => {
        if (id > 0) {
            fetch(
                service.GET_GRN_BY_PO,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setGRNData(result);
                })
                .catch(err => {
                    if (err) {
                        setErrors(err);
                    };
                });
        }
    };
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!supplierId) {
            isValid = false;
            errs[".supplier"] = "Please select supplier";
        }
        if (!purchaseOrderId) {
            isValid = false;
            errs[".purchaseorder"] = "Please select purchase order";
        }
        if (!grnDate) {
            isValid = false;
            errs[".grnDate"] = "Please select date";
        }
        if (!state.quantity) {
            isValid = false;
            errs[".quantity"] = "Please enter quantity";
        }
        if (state.quantity) {
            let recQ = 0;
            grnData.forEach(element => {
                recQ += parseFloat(element.receivedQuantity);
            });
            if ((parseFloat(state.quantity) + parseFloat(recQ)) > parseFloat(state.poquantity)) {
                isValid = false;
                errs[".quantity"] = "Received quantity should not be greater than Ordered Quantity";
            }
        }
        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_GRN,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        GRNDate: moment(grnDate).format("MM/DD/YYYY"),
                        PurchaseOrderId: purchaseOrderId.value,
                        MaterialId: materialId,
                        ReceivedQuantity: state.quantity,
                        BillNo: state.billno,
                        TransportCharges: state.transportcharges,
                        LoadingCharges: state.loadingcharges,
                        TotalAmount: state.totalamount,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const handleDelete = (id) => {
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this record?")) {
            fetch(
                service.DELETE_GRN_DETAILS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        GRNId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
        }
    };
    useEffect(() => {
        setLoading(true);
        getSupplier();
        setLoading(false);
    }, []);

    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
        setPurchaseOrderId(null);
        getPurchaseOrdersBySupplier(ev.value);
    }
    const purchaseOrderChangeHandler = (ev) => {
        setPurchaseOrderId(ev);
        getGRNByPO(ev.value);

        const found = poData.find(obj =>
            obj.purchaseOrderId === ev.value
        );

        setMaterialId(found.materialId);
        setPurchaseDate(moment(found.purchaseOrderDate).format("DD/MM/YYYY"));
        setState({
            ...state, poquantity: found.quantity
        });

    }
    const changeHandler = (event) => {
        if (event.target.name === 'quantity') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        if (event.target.name === 'transportcharges') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        if (event.target.name === 'loadingcharges') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        if (event.target.name === 'totalamount') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }

        setState({ ...state, [event.target.name]: event.target.value });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Material Receipt Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table" style={{ width: '80%' }}>
                                    <TableRow>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '50%' }}>
                                            <Select
                                                options={supplierData}
                                                name="supplier"
                                                value={supplierId}
                                                onChange={supplierChangeHandler}
                                                placeholder="Select Supplier"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplier"]}</div>
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                options={purchaseOrderData}
                                                name="purchaseorder"
                                                value={purchaseOrderId}
                                                onChange={purchaseOrderChangeHandler}
                                                placeholder="Select Purchase Order"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purchaseorder"]}</div>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="Purchase Date" fullWidth value={purchaseDate}
                                                    name="purchaseDate"
                                                    id="purchaseDate"
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="Ordered Quantity" fullWidth value={state.poquantity || ''}
                                                    name="poquantity"
                                                    id="poquantity"
                                                    disabled
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Bill No" fullWidth value={state.billno || ''}
                                                    name="billno"
                                                    onChange={changeHandler}
                                                    id="billno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".billno"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                Receipt Date
                                                <DatePicker
                                                    id="grnDate"
                                                    selected={grnDate}
                                                    onChange={date => setGRNDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".grnDate"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Received Quantity" fullWidth value={state.quantity || ''}
                                                    name="quantity"
                                                    onChange={changeHandler}
                                                    id="quantity"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".quantity"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Transport Charges" fullWidth value={state.transportcharges || ''}
                                                    name="transportcharges"
                                                    onChange={changeHandler}
                                                    id="transportcharges"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".transportcharges"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Loading Charges" fullWidth value={state.loadingcharges || ''}
                                                    name="loadingcharges"
                                                    onChange={changeHandler}
                                                    id="loadingcharges"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".loadingcharges"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Total Amount" fullWidth value={state.totalamount || ''}
                                                    name="totalamount"
                                                    onChange={changeHandler}
                                                    id="totalamount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalamount"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                    || userDesignation === 'Manager - Store' || userDesignation === 'MD'
                                    || userDesignation === 'Jr Mgr - Store' ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                            <br />
                            {loading ? <Oval
                                heigth="100"
                                width="100"
                                color='grey'
                                ariaLabel='loading'
                            /> :
                                <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell colSpan="5" align="center" style={{ fontWeight: 'bold' }}>
                                                Material Receipt Details
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>GRN No</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>GRN Date</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Bill No</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Material</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Received Quantity</TableCell>
                                            {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                || userDesignation === 'Manager - Store' ?
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                : null}
                                        </TableRow>
                                        <TableBody>
                                            {grnData.map((row) => (
                                                <TableRow
                                                    key={row.grnId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{row.grnNo}</TableCell>
                                                    <TableCell component="th" scope="row">{moment(row.grnDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell component="th" scope="row">{row.billNo}</TableCell>
                                                    <TableCell component="th" scope="row">{row.material}</TableCell>
                                                    <TableCell component="th" scope="row">{row.receivedQuantity}</TableCell>
                                                    {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                        || userDesignation === 'Manager - Store' ?
                                                        <TableCell><MDButton onClick={() => handleDelete(row.grnId)}>Delete</MDButton></TableCell>
                                                        : null}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default MaterialReceipt;